/*!
 * Toaststrap v1.1.0
 * 
 * Copyright 2021 Nawaf Khalifah.
 *
*/
$prefix: "toaststrap";

$spaces: 5, 10, 15, 20;
$bgs: (
        primary: --bs-primary-rgb,
        info: --bs-info-rgb,
        success: --bs-success-rgb,
        warning: --bs-warning-rgb,
        danger: --bs-danger-rgb,
        dark: --bs-dark-rgb,
        sweet: #f4a3c4
);

.#{$prefix} {

  &-container {
    display: none;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    cursor: pointer;

    &:is(.show) {
      display: block;

      .toast {
        display: block;
        opacity: 1;
      }
    }

    & .toast > .toast-header:is(.text-light) {
      .btn-close {
        background: transparent url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") center/1em auto no-repeat;
        color: #ffffff;
      }
    }
  }

  &-top {
    top: -155px;
  }

  &-bottom {
    bottom: -155px;
  }

  &-middle {
    top: 50%;
    margin-top: -50px;
  }

  @each $space in $spaces {
    &-start-#{$space} {
      left: $space + px;

      [dir=rtl] & {
        left: unset;
        right: $space + px;
      }
    }

    &-end-#{$space} {
      right: $space + px;

      [dir=rtl] & {
        right: unset;
        left: $space + px;
      }
    }

    &-center-#{$space} {
      margin-left: 0;
      margin-right: 0;
      left: 0;
      right: 0;

      .toast {
        margin: auto;
      }
    }
  }
}

// Footer
.toast-footer {
  height: 3px;
  overflow: hidden;

  &-inner {
    width: 0;
    height: inherit;
    background-color: rgb(var(--bs-secondary-rgb));
    animation: move-progress infinite;

    .#{$prefix}-container:hover & {
      animation-play-state: paused;
    }

    @each $key, $value in $bgs {
      [data-type='#{$key}'] & {
        @if (type-of($value) != color) {
          background-color: rgb(var($value)) !important;
        } @else {
          background-color: $value !important;
        }
      }
    }

    [data-snackbar=true] & {
      background-color: rgba(255,255,255,.85) !important;
    }

  }

}

// Variants
@each $key, $value in $bgs {
  .bg-#{$key} {
    @if (type-of($value) != color) {
      background-color: rgb(var($value)) !important;
    } @else {
      background-color: $value !important;
    }
  }
}

// Responsibility
@media (max-width: 575.98px) {
  .#{$prefix} {
    &-top,
    &-bottom {
      margin-left: 0;
      margin-right: 0;
      left: 0;
      right: 0;

      .toast {
        margin: auto;
      }
    }
  }
}


@keyframes move-progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}